import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import coming_soon from "../../Assets/Projects/coming-soon.png";
import minecraft from "../../Assets/Projects/minecraft.png";
import satisfactory from "../../Assets/Projects/satisfactory.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Meine <strong className="purple">Community </strong>Projekte
        </h1>
        <p style={{ color: "white" }}>
        Hier kannst du überall mitmachen, für mehr Informationen geh auf meinen Discord!
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={minecraft}
              isBlog={false}
              title="Minecraft"
              description=" Kleiner Text"
              targetLink="SERVERIP"
              demoLink={false}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={satisfactory}
              isBlog={false}
              title="Satisfactory"
              description="Kleiner Text" 
              targetLink="SERVERIP"
              demoLink={false}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={coming_soon}
              isBlog={false}
              title="Comming Soon..."
              description="Was hier wohl sein könnte."
              targetLink="huch, ist das ein secret?"
              demoLink={false}             
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
