import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/Plushy-1-2.svg";
import Tilt from "react-parallax-tilt";
import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom"; 
import { BsTiktok } from "react-icons/bs";
import { SiKofi } from "react-icons/si";
import { IoLogoWhatsapp } from "react-icons/io";
import { RiPatreonFill } from "react-icons/ri";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              WICHTIGES <span className="purple"> ÜBER </span> MICH!
            </h1>
            <p className="home-about-body">
              Ich bin PlüschFuchs - Eine Succubus/PolarFuchs VTuberin!
              <br />
              <br />~ Du kannst mich aber natürlich gerne Plüschi nennen
              <br />
              <i>
                <b className="about-section-sub-color"> Pssst.. Plüsch, Stinkefuchs oder kleines etwas passt auch hehe  </b>
              </i>
              <br />
              <br />
              Geboren wurde ich am 09.09.1903. Ja, das bedeutet, dass ich 120 Jahre alt bin! &nbsp; Wie das geht? Die Energie meiner Fans hält mich jun... Daaaa reden wir lieber nicht drüber.
              Lass uns lieber über Games die ich im Stream zocke reden, sowas wie Valorant, Fortnite, Pokemon und diverse andere Spiele kommen da hauptsächlich vor...
              <br />
              <Link className="buttonStyle" to="/about">
              Read More
              </Link>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Schau hier auf jedenfall auch vorbei!</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://tiktok.com/@plueschfuchs/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <BsTiktok />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/plueschfuchs/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://ko-fi.com/plueschfuchs/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <SiKofi />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://whatsapp.com/channel/0029Va9ymecI7BeNXSOjrO0g/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <IoLogoWhatsapp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.patreon.com/plueschfuchs/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <RiPatreonFill />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/plueschfuchs/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
