import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { FaLink } from "react-icons/fa";

const CopyToClipboardButton = ({ textToCopy, onCopy }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        onCopy(true);
      })
      .catch((error) => {
        console.error('Fehler beim Kopieren in die Zwischenablage:', error);
        onCopy(false);
      });
  };

  return (
    <Button variant="primary" onClick={copyToClipboard} className="rounded-circle">
      click to copy&nbsp;
      <FaLink />
    </Button>
  );
};

function ProjectCards(props) {
  const [copied, setCopied] = useState(false);

  const handleCopy = (success) => {
    setCopied(success);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Rückmeldung wird nach 2 Sekunden ausgeblendet
  };

  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        {props.isBlog ? (
          <Button variant="primary" href={props.targetLink} target="_blank">
            <FaLink /> &nbsp;
            Blog
          </Button>
        ) : (
          <>
            <CopyToClipboardButton textToCopy={props.targetLink} onCopy={handleCopy}>
            </CopyToClipboardButton>
            {copied && <span style={{ marginLeft: "5px", color: "white" }}>Server-IP kopiert!</span>}
          </>
        )}
        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Discord"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
