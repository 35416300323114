import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Hi! Willkommen im Fuchsbau, mach es dir bequem und nimm dir einen Chai Latte und nen Keks! 
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Wer ich bin?
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Ich bin PlüschFuchs. Du kannst mich aber gerne Plüschi nennen. Warum habe ich einen Schweif und flauschige Ohren? Ich bin ein Polarfuchs und so ganz nebenbei auch noch ein energiesaugender Succubus. Du kannst mich aber auch, als du ganz bin deine ganz persönliche Anime-Waifu sehen, das bleibt dir selber überlassen. Lass dich bitte nicht von dem Fakt stören, dass ich 120 Jahre alt bin. So ist das halt als Succubus.
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Wieso ich mich hier so selbstverliebt vorstelle?
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Well.. ich bin ContentCreatorin (und das hier ist immerhin meine Seite lel)! Hauptsächlich besteht mein Content aus fast täglichen Streams auf TikTok und aber auch manchmal Twitch sowie (mehr oder weniger) regelmässigen Uploads auf Youtube, TikTok, Instagram und Patreon.
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Ich bin VarietyStreamerin.
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Das bedeutet, ich spiele von Valorant, Fortnite und Pokemon über Fallguys, SuperMario und diverse Indiegames alles, was du dir so vorstellen kannst (ausser Horror, lol).
          </p>
          <p style={{ marginBottom: "10px", color: "rgb(155 126 172)", textAlign: "left" }}>
            "Wenn eine Schraube locker ist, hat das Leben mehr Spiel."
          </p>
          <footer className="blockquote-footer" style={{ textAlign: "left" }}>Dein Succubus Fuchs</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
