import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import h4ppii from "../../Assets/Team/h4ppii.png";
import coming_soon from "../../Assets/Team/coming-soon.png";
import astolfo from "../../Assets/Team/astolfo.png";
import chiyo from "../../Assets/Team/chiyo.png";
import crysler from "../../Assets/Team/crysler.png";
import forbaadok from "../../Assets/Team/forbaken.png";
import len from "../../Assets/Team/len.png";
import nagasumi from "../../Assets/Team/nagasumi.png";
import nightmaresun from "../../Assets/Team/nighmaresun.png";
import nopay from "../../Assets/Team/nopay.png";
import pistation from "../../Assets/Team/pistatio.png";
import temo from "../../Assets/Team/temo.png";
import thomas from "../../Assets/Team/thomas.png";
import luki from "../../Assets/Team/luki.png"
import pluesch from "../../Assets/Team/pluesch.png"



function Team() {
  const categories = [
    {
      title: "",
      members: [
        { name: "Plüschfuchs", role: "Streamer Mama", imgPath: pluesch, description: "" }
      ]
    },
    {
      title: "Admin",
      members: [
        { name: "h4ppii", role: "Discord und Server Techniker", imgPath: h4ppii, description: "" }
      ]
    },
    {
      title: "Sr. Moderator",
      members: [
        { name: "NoPay4You", role: "", imgPath: nopay, description: "" },
        { name: "Nagasumi", role: "", imgPath: nagasumi, description: "" },
        { name: "Thomas", role: "", imgPath: thomas, description: "" },
        { name: "Cosyluki", role: "", imgPath: luki, description: "" }
      ]
    },
    {
      title: "Das Plüschfuchs Team",
      members: [
        { name: "Thomas", role: "Cutter", imgPath: thomas, description: "" },
        { name: "Nightmaresun", role: "Artist", imgPath: nightmaresun, description: "" },
        { name: "ChioChips", role: "Artist", imgPath: chiyo, description: "" },
        { name: "ChryslerXtreme", role: "TikTok Moderator", imgPath: crysler, description: "" },
        { name: "Astolfo", role: "TikTok Moderator", imgPath: astolfo, description: "" },
        { name: "Pistachio", role: "", imgPath: pistation, description: "" },
        { name: "Temo", role: "TikTok Moderator", imgPath: temo, description: "" },
        { name: "forbaadok", role: "Minecraft Moderator", imgPath: forbaadok, description: "" },
        { name: "ombiax", role: "Minecraft und TikTok Moderator", imgPath: len, description: "" }
      ]
    }
  ];

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mein <strong className="purple">Team </strong>
        </h1>
        <p style={{ color: "white" }}>
          Das ist mein kleines aber feines Team ❤️
        </p>
        {categories.map((category, index) => (
          <div key={index}>
            <h2 style={{ color: "ghostwhite" }}> {category.title}</h2>
            <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
              {category.members.map((member, index) => (
                <Col md={2} className="project-card" key={index} style={{ display: "flex", justifyContent: "center", marginBottom: "20px", color: "silver" }}>
                  <div style={{ textAlign: "center" }}>
                    <img src={member.imgPath} alt={member.name} style={{ borderRadius: "50%", width: "150px", height: "150px",}} />
                    <h3 style={{ color: "silver" }}>{member.name}</h3>
                    <p style={{ color: "white" }}>{member.role}</p>
                    <p style={{ color: "ghostwhite" }}>{member.description}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </Container>
    </Container>
  );
}

export default Team;
