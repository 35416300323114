import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard2() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Privat bin ich definitiv sehr introvertiert. Meistens chille ich in meinem Gamingraum und "Arbeite" an Plüschisachen. Mein Lieblingsspiel ist tatsächlich TeamfightTactics und nebenbei liebe ich es mir Videos von BastiGHG, LDShadowlady oder JaidenAnimations anzuschauen. LiveStreams gucke ich selber kaum, aber meine Lieblinge hier sind ShyLily, Snuffy und Ironmouse (Sorry, bin Basic).
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Auch wenn sie alle weiter weg wohnen, treffe ich mich ab und zu mit meinen besten Freunden um ins Kino zu gehen, Karaokebars unsicher zu machen (ich kann nicht singen, lass mich in Ruhe Q_Q) oder einfach nur um Zuhause rumzugammeln.
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Und was eine Überraschung. Ich liebe Tiere, aber leider sind diese hier nicht erlaubt (und gegen vieles bin ich allergisch xD). ABER wenn ich könnte, hätte ich gerne Katzen und Chinchillas! (Oder Frettchen).
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Natürlich arbeite ich auch, aber darüber verrate ich hier nix.
          </p>
          <p style={{ marginBottom: "10px", textAlign: "left" }}>
            Joa, was gibt es mehr zu sagen, was mich nicht komplett wegdoxxed? Ich bin langweilig, was habt ihr eigentlich erwartet?
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard2;
